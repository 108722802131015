<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6><a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> New product</h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label">Name <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="productRequest.name" @blur="validationRule()"
										class="form-control" id="inputName">
									<small class="text-danger">{{validationData.name}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputprice" class="col-sm-2 col-form-label">price<span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="number" v-model="productRequest.price" @blur="validationRule()"
										class="form-control" id="inputprice">
									<small class="text-danger">{{validationData.price}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputdescription" class="col-sm-2 col-form-label">description <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<textarea v-model="productRequest.description" @blur="validationRule()"
										class="form-control" id="inputdescription"></textarea>
									<small class="text-danger">{{validationData.description}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputspecification" class="col-sm-2 col-form-label">specification <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="productRequest.specification" @blur="validationRule()"
										class="form-control" id="inputspecification">
									<small class="text-danger">{{validationData.specification}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputImage" class="col-sm-2 col-form-label">Image <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputImage" @change="readImage">
									<img v-if="productRequest.image" v-bind:src="productRequest.image" class="banner-image mt-2"/>
									<small class="text-danger">{{validationData.image}}</small>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'productCreate',
		metaInfo: {
            title: "Product Create",
            titleTemplate: "%s ← HoHoDiDi Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				currentImage: "",
				productRequest: {
					name: "",
					price: "",
					description: "",
					specification: "",
					image: ""
				},
				validationData: {
					name: "",
					price: "",
					description: "",
					specification: "",
					image: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				productStoreAction: 'productStoreAction',
			}),
			readImage({ target }) {
				const files = target.files
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files[0])
				fileReader.onload = e => {
					this.productRequest.image = e.target.result
					this.validationRule()
				};
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = this.productRequest
				await this.productStoreAction({
					...option
				}).then(res => {
					this.back()
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			back() {
				this.$router.replace({ path: 'product-list', query: {page: 1, reverse: 'desc', sort: 'created_at', name: '',  price: '', description: ''} }).catch(()=>{})
			},
			validationRule() {
				let isValidation = []
				if (this.productRequest.name == "") {
					this.validationData.name = "The name field is required."
					isValidation.push(true)
				} else {
					this.validationData.name = ""
					isValidation.push(false)
				}
				if (this.productRequest.price == "") {
					this.validationData.price = "The price field is required."
					isValidation.push(true)
				} else {
					this.validationData.price = ""
					isValidation.push(false)
				}
				if (this.productRequest.description == "") {
					this.validationData.description = "The description field is required."
					isValidation.push(true)
				} else {
					this.validationData.description = ""
					isValidation.push(false)
				}
				if (this.productRequest.specification == "") {
					this.validationData.specification = "The specification field is required."
					isValidation.push(true)
				} else {
					this.validationData.specification = ""
					isValidation.push(false)
				}
				if (this.productRequest.image == "") {
					this.validationData.image = "The image field is required."
					isValidation.push(true)
				} else {
					console.log(this.productRequest.image)
					this.validationData.image = ""
					isValidation.push(false)
				}
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>